<template>
    <div>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm12 md10 lg10 xl8>
                <v-form id="form_user_data_update" ref="form_user_data_update" v-model="form1" lazy-validation>
                    <v-card>
                        <v-card-title>
                                <span class="subtitle-1"><v-icon left>{{ userIcon }}</v-icon> Podatki o računu <span class="caption" v-if="isUserAdmin">(Administrator)</span></span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('user-data')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <!-- backend validation messages -->
                            <form-validation-messages :messages="apiEmailValidationMessages">
                            </form-validation-messages>

                            <v-text-field
                                v-model="name"  
                                label="Ime in priimek"
                                required
                                readonly
                                append-icon="mdi-account"
                            ></v-text-field>

                            <v-text-field
                                v-model="email"
                                label="E-mail"
                                required
                                :rules="email_rules"
                                append-icon="mdi-email"
                                @keyup.enter="updateEmail()"
                            ></v-text-field>

                            <v-text-field
                                v-model="last_seen"
                                label="Čas zadnje prijave"
                                required
                                readonly
                                append-icon="mdi-calendar-clock"
                            ></v-text-field>
                            
                        </v-card-text>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1" v-if="$vuetify.breakpoint.xsOnly">
                            <v-btn style="margin-bottom:10px;" :disabled="loading" @click="updateEmail()" color="success" block outlined><v-icon left>mdi-arrow-right</v-icon>Posodobi podatke</v-btn>
                            <v-btn :disabled="loading" @click="cancelUserDataUpdate()" color="error" block outlined><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        </v-card-text>
                        <v-card-actions v-show="card1" v-else>
                            <v-btn :disabled="loading" @click="updateEmail()" color="success" text><v-icon left>mdi-arrow-right</v-icon>Posodobi podatke</v-btn>
                            <v-btn :disabled="loading" @click="cancelUserDataUpdate()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        </v-card-actions>
                        <div style="min-height:5px;"></div>
                    </v-card>
                </v-form>
            </v-flex>

            <!-- zamenjava gesla -->
            <v-flex xs12 sm12 md10 lg10 xl8>
                <v-form id="form_password_update" ref="form_password_update" v-model="form2" lazy-validation>
                    <v-card>
                        <v-card-title>
                                <span class="subtitle-1"><v-icon left>mdi-lock-reset</v-icon> Zamenjava uporabniškega gesla</span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('password-reset')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card2"></v-divider>
                        <v-card-text v-show="card2">
                            <!-- backend validation messages -->
                            <form-validation-messages :messages="apiPasswordValidationMessages">
                            </form-validation-messages>
                            <!--<v-text-field
                                v-model="current_password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Trenutno geslo"
                                hint=""
                                counter
                                @click:append="show1 = !show1"
                            ></v-text-field>-->

                            <v-text-field
                                v-model="new_password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Novo geslo"
                                hint=""
                                counter
                                @click:append="show1 = !show1"
                            ></v-text-field>

                            <v-text-field
                                v-model="new_password_confirmation"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min, passwordMatch]"
                                :type="show2 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Ponovno vpišite novo geslo"
                                hint="Geslo naj vsebuje vsaj 6 unakov"
                                counter
                                @click:append="show2 = !show2"
                                @keyup.enter="updatePassword()"
                            ></v-text-field>
                            
                        </v-card-text>
                        <v-divider v-show="card2"></v-divider>
                        <v-card-text v-show="card2"  v-if="$vuetify.breakpoint.xsOnly">
                            <v-btn :disabled="loading" @click="updatePassword()" color="success" block outlined style="margin-bottom:10px;"><v-icon left>mdi-arrow-right</v-icon>Posodobi geslo</v-btn>
                            <v-btn :disabled="loading" @click="cancelPasswordReset()" color="error" block outlined><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        </v-card-text>
                        <v-card-actions v-show="card2" v-else>
                            <v-btn :disabled="loading" @click="updatePassword()" color="success" text><v-icon left>mdi-arrow-right</v-icon>Posodobi geslo</v-btn>
                            <v-btn :disabled="loading" @click="cancelPasswordReset()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        </v-card-actions>
                        <div style="min-height:5px;"></div>
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import { isAdmin, storeUserSettingsToLocalStorage } from '@/helpers/utilities'
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')

export default {
    name: 'edit-account',
    components: {
        FormValidationMessages
    },
    data: () => ({
        admin: false,
        name: null,
        email: null,
        last_seen: null,
        current_password: '',
        new_password: '',
        new_password_confirmation: '',

        card1: true,
        card2: true,

        show1:false,
        show2:false,

        form1: false,
        form2: false,

        rules: {
          required: value => !!value || 'Vnos gesla je obvezen',
          min: v => v.length >= 8 || 'Geslo mora vsebovati vsaj 8 znakov (uporabite številke, znake ter velike in male črke)'
        },

        email_rules: [
            v => !!v || 'Polje e-mail je obvezno',
            v => /.+@.+\..+/.test(v) || 'Vnesen elektronski naslov ni pravilen'
        ],

        show3: false,

        
        backendEmailValidationMessages:[],
        backendPasswordValidationMessages: [],
        loading: false,
    }),

    computed: {
        userIcon() {
            if(isAdmin()) {
                return 'mdi-incognito'
            }

            return 'mdi-account-circle'
        },

        isUserAdmin() {
            if(isAdmin()) {
                return true
            }

            return false
        },

        apiEmailValidationMessages() {
            return this.backendEmailValidationMessages
        },

        apiPasswordValidationMessages() {
            return this.backendPasswordValidationMessages
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'user-data') {
                this.card1 = !this.card1
            }

            if(card === 'password-reset') {
                this.card2 = !this.card2
            }
        },

        getLastSeenData(){
            this.last_seen = this.$store.getters.user.last_seen.date_time
        },

        getUserData() {
            if(this.$store.getters.user.name != null) {
                this.name = this.$store.getters.user.name
            }

            if(this.$store.getters.user.email != null) {
                this.email = this.$store.getters.user.email
            }
        },

        updateEmail() {
            this.backendEmailValidationMessages = []
            let vm = this;
            if(this.$refs.form_user_data_update.validate()) {
                vm.loading = true
                //window.console.log("OK")
                vm.$store.commit('SET_PROGRESS', true)
                vm.$store.commit('SET_LOADER', true)
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev elektronskega naslova je v teku...')
                vm.$store.dispatch('ACCOUNT_EMAIL_UPDATE', { email: vm.email })
                .then(response => {
                    //window.console.log(response.data)
                    //window.console.log(vm.email)
                    vm.$store.commit('SET_USER_EMAIL', vm.email)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Zamenjava elektronskega naslova uporabnika je uspela.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                })
                .catch(error => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri zamenjavi elektronskega naslova je prišlo do težave")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)

                    if(error.response.status === 422) {
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendEmailValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_user_data_update', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    }
                })
                .finally(() =>{
                    vm.$store.commit('SET_LOADER', false)
                    setTimeout(() => {
                        vm.$store.commit('SET_PROGRESS', false)
                    }, 600)
                    vm.loading = false
                })
            }
            else {
                vm.$vuetify.goTo('#form_user_data_update', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }
        },

        cancelUserDataUpdate() {
            this.$refs.form_user_data_update.resetValidation()
            this.getUserData()
            this.getLastSeenData()
            this.backendEmailValidationMessages = []
            this.$vuetify.goTo('#form_user_data_update', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
        },

        updatePassword() {
            this.backendPasswordValidationMessages = []
            let vm = this;
            if(this.$refs.form_password_update.validate()) {
                vm.loading = true
                //window.console.log("OK")
                vm.$store.commit('SET_PROGRESS', true)
                vm.$store.commit('SET_LOADER', true)
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev gesla je v teku...')
                vm.$store.dispatch('ACCOUNT_PASSWORD_UPDATE', { password: vm.current_password, new_password: vm.new_password })
                .then(response => {
                    //window.console.log(response.data)
                    //window.console.log(vm.email)
                    vm.$store.commit('SET_USER_PW_RESET', 1);

                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Zamenjava gesla je uspela.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.cancelPasswordReset()
                })
                .catch(error => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri zamenjavi gesla je prišlo do težave")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)

                    if(error.response.status === 422) {
                        for (const property in error.response.data.errors) {
                        //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                        var propertyData = error.response.data.errors[property];
                        //window.console.log(propertyData);
                        propertyData.forEach(message => {
                            vm.backendPasswordValidationMessages.push(message);
                        })

                        vm.$vuetify.goTo('#form_password_update', {
                            duration: 500,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }
                    }
                })
                .finally(() =>{
                    vm.$store.commit('SET_LOADER', false)
                    setTimeout(() => {
                        vm.$store.commit('SET_PROGRESS', false)
                    }, 600)
                    vm.loading = false
                })
            }
            else {
                vm.$vuetify.goTo('#form_password_update', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }
        },

        cancelPasswordReset() {
            this.$refs.form_password_update.resetValidation()
            this.current_password = ''
            this.new_password = ''
            this.new_password_confirmation = ''
            this.backendPasswordValidationMessages = []
            this.$vuetify.goTo('#form_password_update', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
        },

        passwordMatch() {
            if(this.new_password !== this.new_password_confirmation) {
                return "Gesli se ne ujemata"
            }

            return true
        }
    },

    created() {
        
    },

    mounted() {
        this.getLastSeenData()
        this.getUserData()
        
    }

}

</script>

<style scoped>

</style>